import { h } from 'phy-emotion';
import { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

export default function DatePicker({ id, onDateChange, onFocusChange }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);

  function handleDatesChange({ startDate, endDate }) {
    setStartDate(startDate);
    setEndDate(endDate);
  }

  function handleFocusChange(focusedInput) {
    setFocusedInput(focusedInput);
  }

  return h(DateRangePicker, {
    focusedInput,
    startDate,
    startDateId: `${id}-start-id`,
    endDate,
    endDateId: `${id}-input-end-id`,
    onDatesChange: ({ startDate, endDate }) => {
      handleDatesChange({ startDate, endDate });
      onDateChange && onDateChange({ startDate, endDate });
    },
    onFocusChange: focusedInput => {
      console.log(focusedInput);
      handleFocusChange(focusedInput);
      onFocusChange && onFocusChange(focusedInput);
    },
  });
}
