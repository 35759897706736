import { h } from 'phy-emotion';
import { theme } from '../styles/global';

export default function Input({ children, onChange, styles, ...otherProps }) {
  return h(
    'label',
    { css: { margin: '8px 0', display: 'inline-block', ...styles } },
    [
      children,
      h('input', {
        ...otherProps,
        onChange: ({ target: { value } }) => onChange(value),
        css: {
          marginLeft: theme.size.space[2],

          display: 'block',
          padding: `${theme.size.space[1]} ${theme.size.space[2]}`,
          color: 'inherit',
          width: '100%',
          fontFamily: 'inherit',
          fontSize: theme.font.md,
          fontWeight: 'inherit',
          lineHeight: theme.lineHeight.normal,
          border: '1px solid #dbdbdb',
          borderRadius: '0.4rem',
          // transition: box-shadow var(--transitionDuration);
        },
      }),
    ]
  );
}
