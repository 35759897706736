import { h } from 'phy-emotion';
import { theme } from '../styles/global';

export default function Button({ children, styles, ...otherProps }) {
  return h(
    'a',
    {
      ...otherProps,
      role: 'button',
      css: {
        display: 'inline-block',
        background: theme.color.primary,
        color: 'white',
        padding: '8px 24px',
        borderRadius: '16px',
        fontWeight: 'bold',
        textDecoration: 'none',
        boxShadow:
          '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)',
        ...styles,
      },
    },
    children
  );
}
