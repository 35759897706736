import { h } from 'phy-emotion';
import { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { between, lighten } from 'polished';
import { Icon } from '@iconify/react';
import locationIcon from '@iconify/icons-mdi/map-marker';
import GoogleMapReact from 'google-map-react';
import Layout from '../components/layout';
import { theme } from '../styles/global';
import DatePicker from '../components/date-picker';
import Button from '../components/button';
import Input from '../components/input';
import { getDateISOFormat } from '../utils/date-utils';

const IndexPage = () => {
  const [numberOfAdults, setNumberOfAdults] = useState(1);
  const [numberOfChildren, setNumberOfChildren] = useState(0);
  const [numberOfPets, setNumberOfPets] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
            transformOptions: { fit: COVER, cropFocus: ATTENTION }
          )
        }
      }
    }
  `);

  const LocationPin = ({ text }) =>
    h('div.pin', { css: { color: 'red' } }, [
      h(Icon, { icon: locationIcon, css: { width: 24, height: 24 } }),
      h('p.pin-text', { css: { whiteSpace: 'nowrap' } }, text),
    ]);

  return h(Layout, { title: 'Mountain View Westport' }, [
    h(
      'div',
      {
        css: {
          display: 'grid',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: between(
            '400px',
            '660px',
            `${theme.breakpoints.xs}px`,
            `${theme.breakpoints.lg}px`
          ),
        },
      },
      [
        h(GatsbyImage, {
          image: data.file.childImageSharp.gatsbyImageData,
          loading: 'eager',
          alt: '',
          css: { gridArea: '1/1' },
        }),

        h(
          'div',
          {
            css: {
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: '1/1',
              position: 'relative',
              // This centers the other elements inside the hero component
              placeItems: 'center',
              padding: '320px 0 192px 0',
              display: 'grid',
              color: 'white',
              paddingTop: between(
                '240px',
                '320px',
                `${theme.breakpoints.sm}px`,
                `${theme.breakpoints.lg}px`
              ),
            },
          },
          [
            h(
              'div',
              {
                css: {
                  padding: `${between(
                    theme.size.space[1],
                    theme.size.space[4],
                    `${theme.breakpoints.xs}px`,
                    `${theme.breakpoints.lg}px`
                  )} ${between(
                    theme.size.space[2],
                    theme.size.gutter,
                    `${theme.breakpoints.xs}px`,
                    `${theme.breakpoints.lg}px`
                  )}`,
                },
              },
              [
                h(
                  'h1',
                  { css: { textShadow: '2px 2px 4px black', marginBottom: 0 } },
                  [
                    'Welcome to ',
                    h(
                      'b',
                      { css: { color: lighten(0.3, theme.color.primary) } },
                      'Mountain View Westport!'
                    ),
                  ]
                ),

                h(
                  'h2',
                  {
                    css: { textShadow: '2px 2px 4px black', marginBottom: 64 },
                  },
                  [
                    'At the foot of ',
                    h(
                      'b',
                      { css: { color: lighten(0.3, theme.color.primary) } },
                      'Croagh Patrick Mountain'
                    ),
                  ]
                ),
              ]
            ),

            h(
              Button,
              { href: 'https://www.airbnb.ie/rooms/662885845829512278' },
              'Book now on Airbnb'
            ),
          ]
        ),
      ]
    ),

    h(
      'div',
      {
        css: {
          position: 'relative',
          marginTop: 'calc(520px + 12.43vw)',
        },
      },
      [
        h('form', [
          h(DatePicker, {
            id: 'date-range-input',
            onDateChange: ({ startDate, endDate }) => {
              setStartDate(startDate);
              setEndDate(endDate);
            },
          }),

          h('br'),

          h(
            Input,
            {
              name: 'numberOfAdults',
              type: 'number',
              value: numberOfAdults,
              onChange: value => setNumberOfAdults(value),
              styles: { marginTop: '16px' },
            },
            'Number of adults:'
          ),

          h('br'),

          h(
            Input,
            {
              name: 'numberOfChildren',
              type: 'number',
              value: numberOfChildren,
              onChange: value => setNumberOfChildren(value),
            },
            'Number of children:'
          ),

          h('br'),

          h(
            Input,
            {
              name: 'numberOfPets',
              type: 'number',
              value: numberOfPets,
              onChange: value => setNumberOfPets(value),
            },
            'Number of pets:'
          ),

          h('br'),

          h(
            Button,
            {
              disabled: !startDate || !endDate,
              href:
                startDate &&
                endDate &&
                `https://www.airbnb.ie/book/stays/662885845829512278?checkin=${getDateISOFormat(
                  startDate
                )}&numberOfGuests=${
                  numberOfAdults + numberOfChildren
                }&numberOfAdults=${numberOfAdults}&checkout=${getDateISOFormat(
                  endDate
                )}&guestCurrency=EUR&productId=662885845829512278&isWorkTrip=false&numberOfChildren=${numberOfChildren}&numberOfInfants=0&numberOfPets=${numberOfPets}`,
              styles: { margin: '16px 0' },
            },
            'Book Now'
          ),
        ]),
      ]
    ),

    h(
      'div',
      {
        css: {
          position: 'absolute',
          left: 0,
          right: 0,
          top: between(
            '1100px',
            '1200px',
            `${theme.breakpoints.xs}px`,
            `${theme.breakpoints.lg}px`
          ),
          height: between(
            '480px',
            '640px',
            `${theme.breakpoints.xs}px`,
            `${theme.breakpoints.lg}px`
          ),
        },
      },
      h(
        GoogleMapReact,
        {
          bootstrapURLKeys: { key: 'AIzaSyC_ngA4RbVo_wgD8suGfWFvF5yB4xlL3A8' },
          defaultCenter: {
            lat: 53.7142622,
            lng: -9.6622241,
          },
          defaultZoom: 12,
        },
        [
          h(LocationPin, {
            lat: 53.7142622,
            lng: -9.6622241,
            text: 'F28 Y0E9',
          }),
        ]
      )
    ),

    h(
      'div',
      {
        css: {
          position: 'relative',
          textAlign: 'center',
          marginTop: between(
            '640px',
            '720px',
            `${theme.breakpoints.xs}px`,
            `${theme.breakpoints.lg}px`
          ),
        },
      },
      []
    ),
  ]);
};

export default IndexPage;
